import React, {Component, Fragment} from "react";
import '../menu.css';
import MenuTitle from "../menuTitle";
import MenuHeader from "../menuHeader";
import MenuItems from "../menuItems";

const bites = {
    title: "Bites",
    header: ["", "", "", ""],
    items: [
        ["Josey Baker sourdough bread with olive oil & sea salt", " ", " ", "$6"],
        ["Apricots stuffed with goat cheese + Medjool dates stuffed with pistachios & blue cheese", " ", " ","$6"],
        ["Marcona almonds & olives", " ", " ", "$6"],
        ["Clam dip with fish roe and potato crisps", " ", " ", "$7"]
    ]
}

const cheese = {
    title: "Cheese/Charcuterie/Oysters",
    header: ["", "", "", ""],
    items: [
        ['Cured meats with goat and sheep cheese assortment, house made tapenade and fig compote, crostini', " ", " ", "$18"],
        ["Marin Miyagi Oysters with mignonette", "3/$8", "6/$15", "12/$28"]
    ]
}

const brunchyThangs = {
    title: "Brunchy thangs",
    header: ["", "", "", ""],
    items: [
        ["Portugese sausage w/poached egg and arugula salad  (sub veg sausage)", " ", " ", "$14"],
        ["Avocado toast", " ", " ", "$9"],
        ["Arizmendi assorted baked goods", " ", " ", "$14"],
        ["Spanish Tortilla – eggs, potatoes, onion, brava sauce", " ", " ", "$12"],
        ["Greek yogurt, granola and fruit", " ", " ", "$12"]
    ]
}

const bread = {
    title: "Flatbreads/Toasts",
    header: ["", "", "", ""],
    items: [
        ["Flatbread Prosciutto Pizza with San Marzano tomato sauce, oregano, fresh mozzarella and Pecorino Romano", " ", " ", "$18"],
        ["Toast with figs, ricotta, and honey", " ", " ", "$12"]
    ]
}

const food = [
    bites, cheese, brunchyThangs, bread
];

class BrunchFood extends Component {
    render() {
        const foodRows = food.map(fi => <Fragment>
            <MenuTitle title={fi.title}/>
            <MenuHeader header={fi.header}/>
            <MenuItems item={fi}/>
        </Fragment>)
        return (<table className={'foodTable'} align={'center'}>
            {foodRows}
        </table> )
    }
}
export default BrunchFood;