import React, {Component, Fragment} from "react";
import '../menu.css';
import MenuTitle from "../menuTitle";
import MenuHeader from "../menuHeader";
import MenuItems from "../menuItems";

const house = {
    title: "Wine",
    header: ["Pinks and Whites ", "Region", "Glass", "Bottle"],
    items: [
        ["Vidaubenaise Rose' of Provence, 2020", "Provence, FR", "$10", "$28"],
        ["Jules Taylor Sauvignon Blanc, 2021", "Marlborough, NZ", "$12", "$30"],
        ["Cune \"Monopole\" Viura, 2019", "Rioja Alta, SP", "$12", "$30"],
        ["Domaine Guy Amiot Borgogne Blanc, 2019", "Burgundy, FR", "$14", "$50"]
    ]
};

const wines = {
    title: "",
    header: ["", "region", "glass", "bottle"],
    items: [
        ["F Bomb Grenache blend, 2020", "Lodi, Napa, Ca", "$10", "$24"],
        ["Ministry of the Vinterior Pinot Noi, 2020", "Russian River Valley, Ca", "$12", "$30"],
        ["Domaine des Pasquiers Gigondas (organic), 2018", "Rhone Valley, FR", "$12", "$40"],
        ["Cune Imperial Reserva Tinto 2016", "Rioja, SP", "$14", "$55"],
        ["Chateau St. Georges Bordeaux Blend, Cab Franc/Merlot, 2015", "Bordeaux, FR", "$16", "$65"]
    ]
}

const sparkling = {
    title: "Sparkling",
    header: ["", "region", "glass", "bottle"],
    items: [["Krone\"Borealis\" Brut -Pinot Noir / Chardonnay", "Tulbagh, South Africa", "$10", "$30"],
        ["Champagne Xavier LeConte\"Secret de Femme\"  Pinot Meuneir", "Champagne, FR", "$18", "$60"]]
};

const mimosa  = {
    title: "",
    header: ["", "", "", ""],
    items: [
        ["Mimosa", " ", " ", "$7"]
    ]
}



const beer = {
    title: "Beer on draught",
    header: ["", "", "", ""],
    items: [
        ["Trumer Pils", "", "", "$6"],
        ["Guiness Stout", "", "", "$6"],
        ["Anchor Steam Lager", "", "", "$6"],
        ["Fort Point I.P.A.", " ", " ", "$6"]
    ]
}

const soft = {
    title: "",
    header: ["", "", "", ""],
    items: [
        ["Sodas", " ", " ", "$3"],
        ["San Pelligrino Sparkling Mineral Water  LG", "", "", "$5"],
        ["Bundaberg Ginger Beer", " ", " ", "$4"],
        ["Cappucino/Latte/Americano", "", "", "$5"],
        ["Espresso", " ", " ", "$3"],
        ["Espresso Doppio", "", "", "$4"]
    ]
}
const drinks = [house, wines, sparkling, beer, mimosa, soft];

class BrunchDrinks extends Component {

    render() {
        const drinkRows = drinks.map(drink => <Fragment>
            <MenuTitle title={drink.title}/>
            <MenuHeader header={drink.header}/>
            <MenuItems item={drink}/>
        </Fragment>)
        return (
            <table className={'beverageTable'} align={'center'}>
                {drinkRows}
            </table>
        )
    }
}

export default BrunchDrinks;