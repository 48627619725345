import React, {Component} from "react";

class MenuTitle extends Component {

    render() {
        return (
            <tr>
                <td colSpan={4}>
                    <h2>{this.props.title}</h2>
                </td>
            </tr>
        )
    }
}

export default MenuTitle;