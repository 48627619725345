import React from "react";
import OpenTable, {positions} from "react-opentable";

import "react-opentable/dist/index.css";

export default function Reservations() {
    const [showWidget, updateShowWidget] = React.useState(false);
    const handleClick = () => updateShowWidget((val) => !val);
    return (
        <div className="App">
            <div className="switch-button" onClick={handleClick}>
                {showWidget ? "Hide" : "Show"} Reservations
            </div>
            {showWidget ? (

                <OpenTable
                    rid="1205014"
                    customClassName="custom-ot-wrapper"
                    position={positions.POSITION_UNSET}
                />
            ) : null}
        </div>
    );
}