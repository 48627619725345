import React, { Component } from 'react';
import logo from "./images/logo-gold-leaf.png";

class Content extends Component {
    render() {
        return(<div className={'content'}>

                <img src={logo} vspace={10} className={"nameImage"} alt={'Linden & Laguna'}/>
                <div className={'copy'}>
                    <p>Located in an iconic beaux arts building at the corner of Linden and Laguna in San Francisco’s cosmopolitan and historic Hayes Valley, our intimate corner gathering spot has served the Hayes Valley community for a century plus and has at turns been a bakery, ice cream factory, art bar café and Champagne bar. We honor this substantive legacy by offering tasty, comforting and healthy local fare, an eclectic selection of independent family produced fine wines from California and abroad, and craft brew in an inviting, intimate setting. We strive for a style of service which is decidedly unfussy, attentive and as informative as our guests require.</p>
                </div>
        </div>)
    }
}
export default Content;
