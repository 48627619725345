import React, {Component, Fragment} from "react";

class MenuItems extends Component {

    render() {
        const rows = this.props.item.items.map(item => <tr>
            <td align={"left"}>{item[0]}</td>
            <td>{item[1]}</td>
            <td>{item[2]}</td>
            <td>{item[3]}</td>
        </tr>);
        return (<Fragment>{rows}</Fragment>);
    }
}

export default MenuItems;