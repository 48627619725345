import React, {Component} from "react";

class MenuHeader extends Component {
    render() {
        return (<tr>
            <th>{this.props.header[0]}</th>
            <th>{this.props.header[1]}</th>
            <th>{this.props.header[2]}</th>
            <th>{this.props.header[3]}</th>
        </tr>)
    }
}
export default MenuHeader;