import React, {Component} from "react";
import './menu.css';
import icon from '../images/icon.png'
import Food from "./Dinner/food";
import Drinks from "./Dinner/drinks";
import Brunch from "./Brunch/Brunch";
import Dinner from "./Dinner/Dinner";

class Menu extends Component {
    constructor() {
        super();

        this.state = {
            isDinner: true
        };
        this.handleChangeToBrunch = this.handleChangeToBrunch.bind(this);
        this.handleChangeToDinner = this.handleChangeToDinner.bind(this);
    }
    handleChangeToBrunch(){
        this.setState({isDinner: false});
    }
    handleChangeToDinner(){
        this.setState({isDinner: true});
    }
    render() {
        const isDinner = this.state.isDinner;
        return (
            <div className="Menu">
                <h1>Menu August 2021</h1>

                <div className={'menuMenu'}>
                    <div className={isDinner ? 'menuNavActive' : 'menuNav'} onClick={this.handleChangeToDinner}>Dinner</div>
                    <div className={isDinner ? 'menuNav' : 'menuNavActive'} onClick={this.handleChangeToBrunch}>Brunch</div>
                </div>
                <div className={'menuContainer'}>
                    {isDinner
                        ? <Dinner/>
                        : <Brunch/>
                    }
                </div>

            </div>
        );

    }

}

export default Menu;