import React, {Component} from 'react';
import './App.css';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import img1 from './images/gallery/0.jpg'
import img2 from './images/gallery/1.jpg'
import img3 from './images/gallery/2.jpg'
import img4 from './images/gallery/3.jpg'
import img5 from './images/gallery/4.jpg'
import img6 from './images/gallery/5.jpg'
import img7 from './images/gallery/6.jpg'
import img8 from './images/gallery/7.jpg'
import img9 from './images/gallery/8.jpg'
import img10 from './images/gallery/9.jpg'
import img11 from './images/gallery/10.jpg'
import img12 from './images/gallery/11.jpg'
import img13 from './images/gallery/12.jpg'
import img14 from './images/gallery/13.jpg'
import img15 from './images/gallery/14.jpg'
import img16 from './images/gallery/15.jpg'
import img17 from './images/gallery/16.jpg'
import img18 from './images/gallery/17.jpg'
import img19 from './images/gallery/18.jpg'
import img20 from './images/gallery/19.jpg'
import img21 from './images/gallery/20.jpg'
import img22 from './images/gallery/21.jpg'
import about from './images/about.png';
import gallery from './images/gallery.png';
import menu from './images/menu.png';
import logo from './images/logo-gold-leaf.png';
import icon from './images/icon.png';
import Menu from "./Menu/Menu";
import About from "./about";
import Content from "./content";
import Reservations from "./reservations";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22];
const thumbs = images;

class App extends Component {
    static onImageLoadError(imageSrc, _srcType, errorEvent) {
        console.error(`Could not load image at ${imageSrc}`, errorEvent); // eslint-disable-line no-console
    }

    constructor() {
        super();

        this.state = {
            index: 0,
            isOpen: false,
            current: 0,
            home: true,
            about: false,
            menu: false,
            showOpenTable: true
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.movePrev = this.movePrev.bind(this);
        this.handleNavChangeHome = this.handleNavChangeHome.bind(this);
        this.handleNavChangeAbout = this.handleNavChangeAbout.bind(this);
        this.handleNavChangeMenu = this.handleNavChangeMenu.bind(this);
    }

    handleNavChangeHome() {
        this.setHome();
        document.getElementsByClassName('navButton')
        document.getElementsByClassName('Menu')[0].style.display = "none";
        document.getElementsByClassName('about')[0].style.display = "none";
        document.getElementsByClassName('content')[0].style.display = "block";
    }

    handleNavChangeAbout() {
        this.setAbout();
        document.getElementsByClassName('Menu')[0].style.display = "none";
        document.getElementsByClassName('about')[0].style.display = "block";
        document.getElementsByClassName('content')[0].style.display = "none";
    }

    handleNavChangeMenu() {
        this.setMenu();
        this.setState({current:2});
        document.getElementsByClassName('Menu')[0].style.display = "block";
        document.getElementsByClassName('about')[0].style.display = "none";
        document.getElementsByClassName('content')[0].style.display = "none";

    }

    openLightbox() {
        this.setState({isOpen: true});
    }

    closeLightbox() {
        this.setState({isOpen: false});
    }

    moveNext() {
        this.setState(prevState => ({
            index: (prevState.index + 1) % images.length,
        }));
    }

    movePrev() {
        this.setState(prevState => ({
            index: (prevState.index + images.length - 1) % images.length,
        }));
    }

    setHome() {
        this.setState(previousState => ({
            current: 0
        }));
    }
    setAbout() {
        this.setState({
            current: 1
        });
    }
    setMenu() {
        this.setState({
            current: 2
        });
    }
    render() {
        let lightbox;
        if (this.state.isOpen) {
            lightbox = (
                <Lightbox
                    mainSrc={images[this.state.index]}
                    nextSrc={images[(this.state.index + 1) % images.length]}
                    prevSrc={
                        images[(this.state.index + images.length - 1) % images.length]
                    }
                    mainSrcThumbnail={thumbs[this.state.index]}
                    nextSrcThumbnail={thumbs[(this.state.index + 1) % images.length]}
                    prevSrcThumbnail={
                        thumbs[(this.state.index + images.length - 1) % images.length]
                    }
                    onCloseRequest={this.closeLightbox}
                    onMovePrevRequest={this.movePrev}
                    onMoveNextRequest={this.moveNext}
                    onImageLoadError={App.onImageLoadError}
                    imageTitle={""}
                    imageCaption={""}
                />
            );
        }
        return (
            <div className="App">
                <div className={'reservationsContainer'}>
                    <Reservations/>
                </div>
                <div className="header">
                    <img src={icon} alt={'about'} hspace={20} vspace={5} className={'navImage'}
                         onClick={this.handleNavChangeHome}/>

                    <div className={this.state.current !== 1 ? 'navButton' : 'navButtonActive'} onClick={this.handleNavChangeAbout}>About</div>
                    <div className={this.state.current !== 2 ? 'navButton' : 'navButtonActive'} onClick={this.handleNavChangeMenu}>Menu</div>
                    <div className={'navButton'} id={'navGallery'} onClick={this.openLightbox}>Gallery</div>

                </div>
                <div className={'App-header'}>
                    <Content/>
                    <About/>
                    <Menu/>
                </div>
                <div className={'footer'}>
                    528 Laguna St, San Francisco, CA 94102 : 415.555.1212 : info@lindenandlaguna.com
                </div>
                <div className={'iconImage'}>
                    <img src={icon} height={100} width={70} alt={'Icon'}/>
                </div>
                {lightbox}
                <Menu/>
            </div>
        );

    }

}

export default App;
