import React, {Component} from "react";
import '../menu.css';
import icon from '../../images/icon.png'
import BrunchFood from "./brunchFood";
import BrunchDrinks from "./brunchDrinks";

class Brunch extends Component {
    render() {
        return (
            <div className="Brunch">
                <BrunchFood/>
                <div className={"menuIcon"}>
                    <img src={icon} alt={'L&L Icon'} width={75} />
                </div>
                <BrunchDrinks/>


                <div className={"menuIcon"}>
                    <img src={icon} alt={'L&L Icon'} width={75} />
                </div>

            </div>
        );

    }

}

export default Brunch;