import React, {Component, Fragment} from "react";
import '../menu.css';
import MenuTitle from "../menuTitle";
import MenuHeader from "../menuHeader";
import MenuItems from "../menuItems";

const bites = {
    title: "Bites",
    header: ["", "", "", ""],
    items: [
        ["Josey Baker sourdough bread with olive oil & sea salt", " ", " ", "$6"],
        ["Traditional fresh baked Bavarian Pretzel with Dijon mustard (HaDough)", " ", " ","$6"],
        ["Apricots stuffed with goat cheese + Medjool dates stuffed with pistachios & blue cheese", " ", " ", "$8"],
        ["Marcona almonds & olives", " ", " ", "$8"],
        ["Clam dip with caviar + potato crisps", " ", " ", "$9"]
    ]
}

const cheese = {
    title: "Cheese/Charcuterie/Oysters",
    header: ["", "", "", ""],
    items: [
        ['Artisan meat and cheese assortment, fresh fruit, house made tapenade, fig compote and crostini', " ", " ", "$22"],
        ["Point Reyes Oysters with mignonette", "3/$8", "6/$15", "12/$28"]
    ]
}

const salad = {
    title: "Salad",
    header: ["", "", "", ""],
    items: [
        ["Asian pear + pomegranate seeds with spinach and roasted kale, goat cheese, candied pecans + Sherry vinaigrette", " ", " ", "$14"],
        ["Olive oil poached tuna & white bean with arugula, fennel & rainbow carrot brunoise", " ", " ", "$14"]

    ]
}

const soup = {
    title: "Soup",
    header: ["", "", "", ""],
    items: [
        ["Chicken, kale, and white bean with lemon, topped with yogurt", "", "", "$12"]
    ]
}

const bread = {
    title: "Flatbreads/Toasts",
    header: ["", "", "", ""],
    items: [
        ["Chicken, kale, and white bean with lemon, topped with yogurt", " ", " ", "16"],
        ["Flatbread pizzetta with San Marzano tomato sauce, Calabrese salami, oregano, fresh mozzarella and Pecorino Romano", " ", " ", "18"],
        ["L&L flatbread pizzetta with Zip sauce, Soppressata and Calabrese salami, mushrooms, fresh mozzarella and Pecorino Roman", " ", " ", "18"],
        ["Bruschetta – toasted Josey Baker SF sourdough with Arugula + Prosciutto + goat cheese with roasted pear + Balsamic reduction.", " ", " ", "14"]

    ]
}

const sweets = {
    title: "Sweets",
    header: ["", "", "", ""],
    items: [
        ["Fresh baked daily - fabulous HaDough German Cakes – inquire", " ", " ", "12"]
    ]
}

const food = [
    bites, cheese, salad, soup, bread, sweets
];

class Food extends Component {
   render() {
       const foodRows = food.map(fi => <Fragment>
           <MenuTitle title={fi.title}/>
           <MenuHeader header={fi.header}/>
           <MenuItems item={fi}/>
       </Fragment>)
       return (<table className={'foodTable'} align={'center'}>
           {foodRows}
        </table> )
    }
}
export default Food;