import React, {Component} from 'react';

class About extends Component {
    render() {
        return (<div className={'about'}>
            <h1>About</h1>
            <p>
                <ul>
                    <li className={'heading'}>Hours:</li>
                    <li>Weds: 5-10</li>
                    <li>Thurs: 5-10</li>
                    <li>Friday: 5-12</li>
                    <li>Saturday: 5-12</li>
                    <li>Sunday brunch: 2-9</li>
                    <li>Closed Mon-Tues</li>
                </ul>


                <h3>
                    Location:
                </h3>
                <p> 528 Laguna st.<br/>
                    San Francisco, CA. 94102
                </p>

                <h3>Linden & Laguna</h3>
                <p>Winebar / Café</p>

                <ul>
                    <li className={'heading'}>Chris Nickolopoulos</li>
                    <li>Proprietor</li>
                    <li>528 Laguna st.</li>
                    <li>San Francisco, CA. 94102</li>
                    <li>chris@lindenandlaguna.com</li>
                    <li>415 829 2265</li>


                </ul>
            </p>
        </div>)
    }
}

export default About