import React, {Component} from "react";
import '../menu.css';
import icon from '../../images/icon.png'
import Food from "./food";
import Drinks from "./drinks";

class Dinner extends Component {
    render() {
        return (
            <div className="Dinner">
                <Food/>
                <div className={"menuIcon"}>
                    <img src={icon} alt={'L&L Icon'} width={75} />
                </div>
                <Drinks/>


                <div className={"menuIcon"}>
                    <img src={icon} alt={'L&L Icon'} width={75} />
                </div>

            </div>
        );

    }

}

export default Dinner;